var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"builders",attrs:{"id":"my-html"}},[_c('v-card',{staticClass:"rounded-10",attrs:{"flat":"","elevation":"3"}},[_c('v-card-title',[_c('span',{staticClass:"heading primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none mx-1",attrs:{"color":"primary","disabled":_vm.btnloading},on:{"click":function($event){return _vm.$router.push({ name: _vm.redirect_back })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply-all")]),_vm._v(" Back ")],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":_vm.expand_view ? 3 : 6,"id":"left-side"}},[_c('label',{staticClass:"subtitle-2 primary--text my-2"},[_vm._v(" Invoice Template Name (Required) ")]),_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":_vm.nameRules,"clearable":"","dense":"","clear-icon":"mdi-close-circle-outline","outlined":"","hide-details":"auto","placeholder":"Required"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.showGuide = true}}},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v("mdi-cloud-question")]),_vm._v(" Guide & Rules ")],1),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none ma-1",attrs:{"color":"primary"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-dots-horizontal-circle-outline ")]),_vm._v(" Upload or Clone Template ")],1)]}}],null,true)},[_c('span',[_vm._v("Upload or Clone existing template")])])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.inputfile.click()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-cloud-upload-outline")])],1),_c('v-list-item-title',[_vm._v("Upload file")])],1),_c('v-divider'),_c('v-subheader',[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v("mdi-content-duplicate")]),_vm._v(" Clone from below existing templates ")],1),(_vm.templates.length < 1)?_c('v-list-item',[_c('v-list-item-title',{staticClass:"text-center subtitle-1 py-4"},[_vm._v(" No templates yet! ")])],1):_vm._l((_vm.templates),function(template){return _c('v-list-item',{key:template.id,on:{"click":function($event){return _vm.cloneFrom(template)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("ucwords")(template.name))+" ")])],1)})],2)],1)],1),_c('input',{ref:"inputfile",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".txt,.html,.htm"},on:{"change":_vm.getFile}})],1),_c('label',{staticClass:"subtitle-2 primary--text my-2"},[_vm._v(" Invoice Template HTML (Required) ")]),_c('v-textarea',{staticClass:"border-1 border-color--grey mb-1",staticStyle:{"max-height":"1000px","overflow-y":"auto"},attrs:{"hide-details":"auto","outlined":"","rules":_vm.requiredRules,"rows":19,"auto-grow":"","clearable":"","clear-icon":"mdi-close-circle-outline","placeholder":"<div style='width:100%;'>...</div>"},on:{"click:clear":function($event){_vm.html = '';
              _vm.expand_view = false;}},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}}),_c('label',{staticClass:"subtitle-2 primary--text mb-2 my-4"},[_vm._v("Template Preview Image")]),_c('v-file-input',{attrs:{"outlined":"","dense":"","accept":"image/jpeg,image/png,image/gif","clearable":"","clear-icon":"mdi-close-circle-outline","placeholder":"Upload preview","hide-details":"auto","persistent-hint":"","hint":"Better image dimension of 150x300px or image on ratio of 1:2","prepend-icon":"mdi-file-image"},on:{"change":_vm.uploadImage,"click:clear":function($event){_vm.image_uploaded = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","outlined":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}])}),(_vm.image_uploaded)?_c('v-sheet',{staticClass:"ma-2 fullwidth"},[_c('v-img',{attrs:{"max-height":"400","contain":"","src":_vm.image_uploaded.url_inline}})],1):_vm._e()],1),_c('v-col',{attrs:{"md":_vm.expand_view ? 9 : 6}},[(_vm.html)?_c('v-btn',{staticClass:"expand-view",attrs:{"color":"primary","fab":"","x-small":""},on:{"click":function($event){_vm.expand_view = !_vm.expand_view}}},[_c('v-icon',[_vm._v(" "+_vm._s(!_vm.expand_view ? "mdi-arrow-expand-left" : "mdi-arrow-expand-right")+" ")])],1):_vm._e(),_c('HtmlPreviewer',{model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-none mx-1",attrs:{"color":"primary","disabled":_vm.btnloading},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reply-all")]),_vm._v(" Back ")],1),_c('v-btn',{staticClass:"text-none mx-1",attrs:{"color":"primary","loading":_vm.btnloading,"disabled":_vm.html.length < 20},on:{"click":function($event){_vm.editItem ? _vm.update() : _vm.save()}}},[_vm._v(" "+_vm._s(_vm.save_label)+" ")]),_c('v-spacer')],1)],1),_c('InvoiceTemplateGuide',{model:{value:(_vm.showGuide),callback:function ($$v) {_vm.showGuide=$$v},expression:"showGuide"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }