<template>
  <div class="builders" id="my-html">
    <v-card flat elevation="3" class="rounded-10">
      <v-card-title>
        <span class="heading primary--text font-weight-bold">{{ page_title }}</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary"
          :disabled="btnloading"
          class="text-none mx-1"
          @click="$router.push({ name: redirect_back })"
        >
          <v-icon left>mdi-reply-all</v-icon>
          Back
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col :md="expand_view ? 3 : 6" id="left-side">
            <label class="subtitle-2 primary--text my-2">
              Invoice Template Name (Required)
            </label>
            <v-text-field
              :rules="nameRules"
              v-model="title"
              clearable dense
              clear-icon="mdi-close-circle-outline"
              outlined
              hide-details="auto"
              class="mb-2"
              placeholder="Required"
            ></v-text-field>
            <v-row dense class="mb-1">
              <v-col cols="12">
                <v-btn color="primary"
                  class="text-none ma-1"
                  @click="showGuide = true"
                >
                  <v-icon color="red" left>mdi-cloud-question</v-icon>
                  Guide & Rules
                </v-btn>
                <v-menu bottom right offset-y>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="primary"
                          class="text-none ma-1"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon left>
                            mdi-dots-horizontal-circle-outline
                          </v-icon>
                          Upload or Clone Template
                        </v-btn>
                      </template>
                      <span>Upload or Clone existing template</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-list-item @click="$refs.inputfile.click()">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-cloud-upload-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Upload file</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>
                      <v-icon color="red" left>mdi-content-duplicate</v-icon>
                      Clone from below existing templates
                    </v-subheader>
                    <v-list-item v-if="templates.length < 1">
                      <v-list-item-title class="text-center subtitle-1 py-4">
                        No templates yet!
                      </v-list-item-title>
                    </v-list-item>
                    <template v-else>
                      <v-list-item
                        v-for="template in templates"
                        :key="template.id"
                        @click="cloneFrom(template)"
                      >
                        <v-list-item-title>
                          {{ template.name | ucwords }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
              <input
                type="file"
                @change="getFile"
                ref="inputfile"
                accept=".txt,.html,.htm"
                style="display: none"
              />
            </v-row>
            <label class="subtitle-2 primary--text my-2">
              Invoice Template HTML (Required)
            </label>
            <v-textarea
              class="border-1 border-color--grey mb-1"
              hide-details="auto"
              outlined
              :rules="requiredRules"
              :rows="19"
              style="max-height: 1000px; overflow-y: auto"
              auto-grow
              clearable
              clear-icon="mdi-close-circle-outline"
              @click:clear="
                html = '';
                expand_view = false;
              "
              v-model="html"
              placeholder="<div style='width:100%;'>...</div>"
            >
            </v-textarea>
            <label class="subtitle-2 primary--text mb-2 my-4">Template Preview Image</label>
            <v-file-input
              outlined dense
              accept="image/jpeg,image/png,image/gif"
              clearable
              clear-icon="mdi-close-circle-outline"
              placeholder="Upload preview"
              hide-details="auto" persistent-hint hint="Better image dimension of 150x300px or image on ratio of 1:2"
              prepend-icon="mdi-file-image"
              @change="uploadImage"
              @click:clear="image_uploaded = null"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label outlined>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <v-sheet class="ma-2 fullwidth" v-if="image_uploaded">
              <v-img
                max-height="400"
                contain
                :src="image_uploaded.url_inline"
              ></v-img>
            </v-sheet>
          </v-col>
          <v-col :md="expand_view ? 9 : 6">
            <v-btn
              v-if="html"
              color="primary"
              class="expand-view"
              @click="expand_view = !expand_view"
              fab
              x-small
            >
              <v-icon>
                {{
                  !expand_view
                    ? "mdi-arrow-expand-left"
                    : "mdi-arrow-expand-right"
                }}
              </v-icon>
            </v-btn>
            <HtmlPreviewer v-model="html"></HtmlPreviewer>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="primary"
          :disabled="btnloading"
          class="text-none mx-1"
          @click="$router.go(-1)"
        >
          <v-icon left>mdi-reply-all</v-icon>
          Back
        </v-btn>
        <v-btn
          color="primary"
          :loading="btnloading"
          class="text-none mx-1"
          :disabled="html.length < 20"
          @click="editItem ? update() : save()"
        >
          {{ save_label }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <InvoiceTemplateGuide v-model="showGuide"></InvoiceTemplateGuide>
  </div>
</template>

<script>
import Editor from "@/common/Editor/Editor.vue";
import request from "@/services/axios_instance";
import InvoiceTemplateGuide from "@/modules/Builders/Guides/InvoiceTemplateGuide.vue";
import { mapActions, mapGetters } from "vuex";
import HtmlPreviewer from "./components/HtmlPreviewer.vue";

export default {
  name: "InvoiceTemplateBuilder",
  components: { Editor, InvoiceTemplateGuide, HtmlPreviewer },
  data: () => ({
    editorKey: 0,
    expand_view: false,
    html: "",
    title: null,
    btnloading: false,
    showGuide: false,
    placeholders: [
      "target_first_name",
      "target_last_name",
      "target_fullname",
      "target_organization",
      "target_email",
      "from_website",
      "from_organization",
    ],
    nameRules: [
      (v) => !!v || "Template name is required!",
      (v) => (v && v.length > 2) || "Min characters is 3",
      (v) => (v && v.length < 61) || "Max characters is 60",
    ],
    requiredRules: [(v) => !!v || "Template HTML is required"],
    paths: [
      {
        text: "Dashboard",
        disabled: false,
        route: { name: "default-content" },
      },
      { text: "Builders", disabled: true, route: null },
      { text: "Invoice Templates", disabled: true, route: null },
    ],
    editItem: null,
    isAdminEdit: false,
    redirect_back: "invoice",
    image_uploaded: null,
  }),
  watch: {
    "$route.params": {
      handler: function (val) {
        if (val.hasOwnProperty("type")) {
          this.isAdminEdit = val.type === "admin" ? true : false;
        }
        if (val.hasOwnProperty("id")) {
          this.fetchInvoiceTemplate();
        }
      },
      immediate: true,
      deep: true,
    },
    "$route.query.redirect_back": {
      handler: function (val) {
        this.redirect_back = val ? val : "invoice";
      },
      immediate: true,
      deep: true,
    },
    editItem: {
      handler: function (val) {
        if (val) {
          this.html = val.content;
          this.title = val.name;
          this.image_uploaded = val.preview;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$event.$emit("path-change", this.paths);
    this.fetchTemplates();
  },
  computed: {
    ...mapGetters("invoice", ["templates"]),
    page_title() {
      return this.editItem
        ? "Update Invoice Template"
        : "Build Invoice Template";
    },
    save_label() {
      return this.editItem
        ? "Update Invoice Template"
        : "Save Invoice Template";
    },
    showApi() {
      return this.isAdminEdit
        ? `api/admin-templates/${this.$route.params.id}?type=invoice`
        : `api/template/invoices/${this.$route.params.id}`;
    },
    createApi() {
      return this.isAdminEdit
        ? "api/admin-templates/invoice"
        : `api/template/invoices`;
    },
    updateApi() {
      return this.isAdminEdit
        ? `api/admin-templates/invoice/${this.editItem.id}`
        : `api/template/invoices`;
    },
  },
  methods: {
    ...mapActions("invoice", ["fetchTemplates"]),
    fetchInvoiceTemplate() {
      request.get(this.showApi).then(({ data }) => {
        this.editItem = data;
      });
    },
    appendPlaceholder(value) {
      this.mapping.message = this.mapping.message
        ? `${this.mapping.message} [${value}]`
        : `[${value}]`;
      this.editorKey++;
    },
    save() {
      if (!this.title) {
         this.appSnackbar(
          "Template title is required",
          "error"
        );
        return false;
      }
      if (!this.html || this.html.length < 6) {
        this.appSnackbar( "Invalid HTML template", "error");
        return false;
      }
      this.btnloading = true;
      request
        .post(this.createApi, {
          title: this.title,
          html: this.html,
          image: this.image_uploaded,
        })
        .then(({ data }) => {
          this.appSnackbar( "Template successfully created!");
          this.$router.push({ name: this.redirect_back });
        })
        .finally(() => {
          this.btnloading = false;
        });
    },
    update() {
      if (!this.title) {
         this.appSnackbar(
          "Template title is required",
          "error"
        );
        return false;
      }
      if (!this.html || this.html.length < 6) {
        this.appSnackbar( "Invalid HTML template", "error");
        return false;
      }
      this.btnloading = true;
      request
        .put(this.updateApi, {
          id: this.editItem.id,
          title: this.title,
          html: this.html,
          image: this.image_uploaded,
        })
        .then(({ data }) => {
          this.appSnackbar( "Template successfully updated!");
          this.$router.push({ name: this.redirect_back });
        })
        .finally(() => {
          this.btnloading = false;
        });
    },
    getFile(event) {
      const input = event.target;
      if ("files" in input && input.files.length > 0) {
        this.placeFileContent(
          document.getElementById("content-target"),
          input.files[0]
        );
      }
    },
    placeFileContent(target, file) {
      this.readFileContent(file)
        .then((content) => {
          this.html = content;
        })
        .catch((error) => console.log(error));
    },
    readFileContent(file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    },
    cloneFrom(template) {
      if (template.hasOwnProperty("content")) {
        this.html = template.content;
      } else {
         this.appSnackbar(
          "Template has no valid HTML contents",
          "error"
        );
      }
    },
    uploadImage(file) {
      if (file) {
        if (!file.type.includes("image/")) {
          this.appSnackbar( "File is not an image", "error");
          return;
        }
        if (file.size > 10 * 1000000) {
          //10mb max
           this.appSnackbar(
            "File exceed 10MB, cannot be upload",
            "error"
          );
          return;
        }
        let formData = new FormData();
        formData.append("file", file);
        request.post(`api2/attachments/dropzone`, formData).then(
          ({ data }) => {
            this.image_uploaded = data;
          },
          (error) => {
            this.image_uploaded = null;
             this.appSnackbar(
              "File upload error:" + error,
              "error"
            );
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
 
 
.expand-view {
  position: absolute;
  right: 0;
  margin-top: 5px;
}
</style>